import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, withRouter} from 'react-router';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import {Breakpoints, Layout, PageNotFound} from 'wobi-web-common';
import {Route, Switch} from 'react-router-dom';
import queryString from 'query-string';
import {useIdleTimer} from 'react-idle-timer';
import GettingStarted from 'steps/GettingStarted';
import {routes, GettingStartedRoute, DetailsRoute, OffersRoute, ThankYouRoute, OverUseRoute} from 'config/routes';
import DetailsStep from 'steps/Details';
import OffersStep from 'steps/Offers';
import ProductType from 'consts/product-type';
import ThankYouStep from 'steps/ThankYou';
import {constructCampaignArgs, fireGtmEvent} from 'utils/marketingUtils';
import {getPolicyTypeFromStorage, setOffersFields, clearOffersFields} from 'utils/persistOfferDetails';
import OverUse from 'steps/OverUse';
import SideBar from './SideBar';
import Header from './Header';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    [`@media screen and (max-width: ${Breakpoints.mobile}px)`]: {
      padding: '0 25px',
    },
  },
}));

const Routes = ({location}) => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showOnlyBaseHeader, setShowOnlyBaseHeader] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(true);
  const [polType, setPolType] = React.useState('');

  const handleOnIdle = () => {
    fireGtmEvent('RefreshFirstStep');
    clearOffersFields();
    sessionStorage.clear();

    // commented out for a reason, awaiting on product response for whether it's needed or not
    // const policyType = getPolicyTypeFromStorage();
    // setOffersFields({policyType});

    window.location.href = process.env.REACT_APP_HOME_PRODUCT_PAGE || '/';
  };

  useIdleTimer({
    timeout: 1000 * 60 * Number(process.env.REACT_APP_SESSION_TIMEOUT || 30),
    onIdle: handleOnIdle,
    debounce: 500,
  });

  React.useEffect(() => {
    const currentRoute = routes.find(route => route.pathname === location.pathname);
    setShowSidebar(currentRoute ? Boolean(currentRoute.showSidebar) : false);
    setShowHeader(currentRoute ? Boolean(currentRoute.showHeader) : true);
    setShowOnlyBaseHeader(currentRoute ? Boolean(currentRoute.showOnlyBaseHeader) : true);
  }, [location]);

  React.useEffect(() => {
    const oldPolicyType = getPolicyTypeFromStorage();
    if (location?.pathname === '/' || location?.pathname === '/getting-started') {
      let policyType = location.hash.replace('#', '').toUpperCase();
      if (policyType.includes('?')) {
        policyType = policyType.slice(0, policyType.indexOf('?'));
      }
      if (!Object.values(ProductType).includes(policyType)) {
        policyType = oldPolicyType || ProductType.Mixed;
      }
      const values = queryString.parse(location.search || location.hash.split('?')[1]);
      const campaign = constructCampaignArgs(values);
      setOffersFields({...campaign, policyType});
      fireGtmEvent('homeStartFlow', {...campaign, policyType});
      setPolType(policyType);
    }
  }, []);
  const isMobile = useMediaQuery(`(max-width:${Breakpoints.mobile}px)`);

  const classes = useStyles();
  return (
    <Layout
      sidebar={showSidebar ? <SideBar /> : false}
      header={
        showHeader ?
          <Header hasContactUs={!showSidebar || isMobile} showOnlyBaseHeader={showOnlyBaseHeader} polType={polType}/> :
          false
      }
    >
      <div className={classes.wrapper}>
        <Switch>
          <Route exact path={GettingStartedRoute.pathname} component={GettingStarted} />
          <Route exact path={DetailsRoute.pathname} component={DetailsStep} />
          <Route exact path={OffersRoute.pathname} component={OffersStep} />
          <Route exact path={ThankYouRoute.pathname} component={ThankYouStep} />
          <Route exact path={OverUseRoute.pathname} component={OverUse} />
          <Route exact path='/'>
            <Redirect to={GettingStartedRoute.pathname} />
          </Route>
          <Route path='*'>
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

Routes.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }),
};

export default withRouter(Routes);
